import React from 'react';
import data from '@data/template-3/homepage.json';
import { graphql } from 'gatsby';
import Layout from '@components/commons/template-3/layout';
import Intro from '@containers/template-3/faq/intro';
import { getRandomFaqs } from '../../utils/getRandomFaqs';
// import Subscribe from '@containers/template-3/homepage/subscribe';

const IndexPage = ({
    data: {
        allStrapiWebsites: { nodes },
        allStrapiFaqs,
    },
}) => {
    const businessData = nodes[0].business;

    const businessLayoutData = {
        logo: businessData.logoImage?.localFile?.childCloudinaryAsset.fluid ?? null,
        phone: businessData.phone,
        email: nodes[0].trade_pro.email,
        singleLineAddress: businessData.address?.singleLineAddress,
        socialProfiles: businessData.socialProfiles,
        ...businessData
    };

    const seoData = {
        title : businessData.displayName,
        description : businessData.shortDescription,
        domain : nodes[0].websiteDomain.name,
        logo : businessData?.logoImage?.url ?? null,
        previewImage : businessData.coverImage?.url ?? null
    };

    const faqData = {
        ...data.faq,
        faqs: getRandomFaqs(allStrapiFaqs.nodes) 
    }

    // const subscribeData = {
    //     ...data.subscribe
    // }
   

    return (
        <Layout businessData={ businessLayoutData } seoData={ seoData } faqs={faqData}>
            <Intro data={ faqData } />
            {/* <Subscribe data={ subscribeData }/> */}
        </Layout>
    );
};

export const query = graphql`
    query($strapiId: Int!) {
        allStrapiWebsites(filter: { strapiId: { eq: $strapiId } }) {
            nodes {
                websiteDomain {
                    name
                  }
                trade_pro {
                    email
                }
                business {
                    logoImage {
                        url
                        localFile {
                            childCloudinaryAsset {
                                fluid(maxWidth: 200) {
                                    ...CloudinaryAssetFluid
                                }
                            }
                        }
                    }
                    phone
                    socialProfiles {
                        id
                        name
                        url
                    }
                    displayName
                    shortDescription
                    businessFeatures {
                        name
                    }
                    coverImage {
                        url
                        localFile {
                            childCloudinaryAsset {
                                fluid(maxWidth: 1100) {
                                    ...CloudinaryAssetFluid
                                }
                            }
                        }
                    }
                    highlightedServices {
                        text
                        title
                        photo {
                            url
                            localFile {
                                childCloudinaryAsset {
                                    fluid(maxWidth: 1400) {
                                        ...CloudinaryAssetFluid
                                    }
                                }
                            }
                        }
                    }
                    images {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 2000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    process {
                        longDescription
                        shortDescription
                        title
                    }

                    feedbacks {
                        review
                        fullName
                        img {
                            url
                        }
                    }
                    radiusServed
                    address {
                        singleLineAddress
                        longitude
                        latitude
                    }
                }
            }
        }
        allStrapiFaqs{
            nodes {
              websites {
                id
              }
              question
              answer
            }
          }
    }
`;

export default IndexPage;
