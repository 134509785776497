import React, {useState, useEffect, useRef} from 'react';

const Paginate = ({
    items,
    perPage,
    pageSize,
    currentPage,
    setCurrentPage,
    ...props
}) => {
	
	const handleChangePage = (page) => {
		setCurrentPage(page);
	};
	
	const activePage = (page) => {
		if (page === currentPage) {
			return 'active';
		}
		return '';
	};
	
	const htmlPaginate = () => {
		let html = []
		for (let i = 1; i <= pageSize; i++) {
			let page = i;
			if (i < 10) {
				page = `0${i}`;
			}
			html.push(<div
				onClick={() => handleChangePage(i)}
				className={`paginate-item px-10px py-10px xl:px-14px xl:py-14px font-16px xl:font-18px w-42px h-42px xl:w-52px xl:h-52px mx-5px ${activePage(i)}`}
			>
				{page}
			</div>)
		}
		
		return (<div className="flex flex-wrap">{html}</div>)
	}
	
	return (
		<div className="text-center flex items-center justify-center mt-50px mb-60px xl:mt-100px xl:mb-150px">
			{htmlPaginate()}
		</div>
	);
};
export default Paginate;
